export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87')
];

export const server_loads = [2];

export const dictionary = {
		"/(app)/(main)": [~10,[2],[3]],
		"/(app)/(page)/bezpecnost": [~22,[2,5],[3]],
		"/(app)/(page)/cookies": [~23,[2,5],[3]],
		"/(app)/(page)/dekujeme-nepokryto": [~25,[2,5],[3]],
		"/(app)/(page)/dekujeme": [~24,[2,5],[3]],
		"/(app)/(page)/developer": [~26,[2,5],[3]],
		"/(app)/(page)/dostupnost/dekujeme": [~27,[2,5],[3]],
		"/(app)/(page)/downloads": [~28,[2,5],[3]],
		"/(app)/(page)/firmy": [~29,[2,5],[3]],
		"/(app)/(page)/form": [~30,[2,5],[3]],
		"/(app)/(page)/form/partner": [~31,[2,5],[3]],
		"/(app)/(page)/form/partner/api": [~32,[2,5],[3]],
		"/(app)/(page)/gdpr": [~33,[2,5],[3]],
		"/(app)/(page)/hodnoceni/1-1": [~34,[2,5],[3]],
		"/(app)/(page)/hodnoceni/2-3": [~35,[2,5],[3]],
		"/(app)/(page)/hodnoceni/dekujeme": [~36,[2,5],[3]],
		"/(app)/(page)/hotspot": [~37,[2,5],[3]],
		"/(app)/(page)/info": [~38,[2,5],[3]],
		"/(app)/(page)/info/bezdrat": [~39,[2,5],[3]],
		"/(app)/(page)/info/ipv6": [~40,[2,5],[3]],
		"/(app)/(page)/info/optika": [~41,[2,5],[3]],
		"/(app)/(page)/info/starnet": [~42,[2,5],[3]],
		"/(app)/(main)/internet": [~11,[2],[3]],
		"/(app)/(page)/jiny-poskytovatel": [~43,[2,5],[3]],
		"/(app)/(main)/kariera": [~12,[2],[3]],
		"/(app)/(main)/kariera/dekujeme": [~17,[2],[3]],
		"/(app)/(main)/kariera/(position)/realizace-pripojek": [~13,[2,4],[3]],
		"/(app)/(main)/kariera/(position)/regionalni-zastupce": [~14,[2,4],[3]],
		"/(app)/(main)/kariera/(position)/technicka-podpora": [~15,[2,4],[3]],
		"/(app)/(main)/kariera/(position)/vystavba-optiky": [~16,[2,4],[3]],
		"/(app)/(page)/kontakt": [~44,[2,5],[3]],
		"/(app)/(page)/kontakt/dekujeme": [~45,[2,5],[3]],
		"/(app)/(page)/nabidka": [~46,[2,5],[3]],
		"/(app)/(page)/navody": [~47,[2,5],[3]],
		"/(app)/(page)/navody/platby": [~48,[2,5],[3]],
		"/(app)/(page)/objednavka/business": [~49,[2,5],[3]],
		"/(app)/(page)/objednavka/dekujeme": [~50,[2,5],[3]],
		"/(app)/(page)/objednavka/ip-adresa": [~51,[2,5],[3]],
		"/(app)/(page)/objednavka/optika": [~52,[2,5],[3]],
		"/(app)/(page)/objednavka/tv": [~53,[2,5],[3]],
		"/(app)/(page)/oblast": [~54,[2,5],[3]],
		"/(app)/(page)/partner/seznam": [~55,[2,5],[3]],
		"/(app)/(page)/podminky-a-ustanoveni": [~56,[2,5],[3]],
		"/(app)/(page)/podpora/(technical)": [~59,[2,5,7,9],[3]],
		"/(app)/(page)/podpora/(technical)/e-mail": [~60,[2,5,7,9],[3]],
		"/(app)/(page)/podpora/(invoicing)/fakturace": [~57,[2,5,7,8],[3]],
		"/(app)/(page)/podpora/(invoicing)/fakturace/platby": [~58,[2,5,7,8],[3]],
		"/(app)/(page)/podpora/(technical)/nefunguje-net": [~61,[2,5,7,9],[3]],
		"/(app)/(page)/podpora/(technical)/potize-net": [~62,[2,5,7,9],[3]],
		"/(app)/(page)/podpora/(technical)/potize-tv": [~63,[2,5,7,9],[3]],
		"/(app)/(page)/podpora/(technical)/ps-xbox": [~64,[2,5,7,9],[3]],
		"/(app)/(page)/podpora/(technical)/router": [~65,[2,5,7,9],[3]],
		"/(app)/(page)/podpora/(technical)/router/nefunguje": [~66,[2,5,7,9],[3]],
		"/(app)/(page)/podpora/(technical)/router/zapojeni": [~67,[2,5,7,9],[3]],
		"/(app)/(page)/podpora/(technical)/rychlost": [~68,[2,5,7,9],[3]],
		"/(app)/(page)/pronajem": [~69,[2,5],[3]],
		"/(app)/(page)/pronajem/dekujeme": [~70,[2,5],[3]],
		"/(app)/(page)/ps": [~71,[2,5],[3]],
		"/(app)/(page)/ps/prepis": [~72,[2,5],[3]],
		"/(app)/(page)/ps/stehovani": [~73,[2,5],[3]],
		"/(app)/(page)/reference": [~74,[2,5],[3]],
		"/(app)/(page)/reklamace": [~75,[2,5],[3]],
		"/(app)/(page)/router": [~76,[2,5],[3]],
		"/(app)/(page)/router/objednavka": [~77,[2,5],[3]],
		"/(app)/(page)/router/objednavka/dekujeme": [~78,[2,5],[3]],
		"/(app)/(page)/rychlost": [~79,[2,5],[3]],
		"/(app)/(page)/soutez/podminky": [~80,[2,5],[3]],
		"/(app)/(main)/startel": [~18,[2],[3]],
		"/(app)/(main)/startel/dekujeme": [~19,[2],[3]],
		"/(app)/(main)/televize": [~20,[2],[3]],
		"/(app)/(page)/televize/set-top-box": [~81,[2,5],[3]],
		"/(app)/(page)/vypadek": [~82,[2,5],[3]],
		"/(app)/(page)/whistleblower": [~83,[2,5],[3]],
		"/(app)/(page)/zakaznik": [~84,[2,5],[3]],
		"/(app)/(page)/zakaznik/odeslano": [~85,[2,5],[3]],
		"/(app)/(page)/zasady-cookies": [~86,[2,5],[3]],
		"/(app)/(page)/zasady-ochrany-osobnich-udaju": [~87,[2,5],[3]],
		"/(app)/(page)/[...path]": [~21,[2,5],[3,,6]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';